import { createApp } from "vue";
import App from "./App.vue";
import HomePage from "./HomePage.vue";
import BlogPage1 from "./components/BlogPage1.vue";
import BlogPage2 from "./components/BlogPage2.vue";
import "preline/dist/preline";
import "./style.css";
import { createRouter, createWebHistory } from "vue-router";
import NotFound from "./components/NotFound.vue";
import VueGtag from "vue-gtag";

// Define routes
const routes = [
    { path: "/", component: HomePage },
    { path: "/blog1", component: BlogPage1 },
    { path: "/blog2", component: BlogPage2 },
    { path: "/:catchAll(.*)", component: NotFound },
];

// Create router instance
/* eslint-disable no-unused-vars */
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            // Scroll to the element with the corresponding ID
            const element = document.getElementById(to.hash.substring(1));
            if (element) {
                return window.scrollTo({
                    top: element.offsetTop,
                    behavior: "smooth",
                });
            }
            return { top: 0 };
        }
        // Always scroll to top for new routes
        return { top: 0 };
    },
});
/* eslint-enable no-unused-vars */

// Create Vue app
const app = createApp(App);

// Use vue-gtag
app.use(
    VueGtag,
    {
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TOKEN },
    },
    router
);

// Use router
app.use(router);

// Mount app
app.mount("#app");
