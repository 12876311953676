<template>
    <footer
        class="background-gradient w-full py-6 px-4 sm:px-6 lg:px-8 mx-auto"
    >
        <!-- Grid -->
        <div class="max-w-[85rem] mx-auto grid grid-cols-2">
            <div class="mt-3 text-left">
                <p class="text-gray text-sm font-body">
                    © Waive Consulting. 2024 Amsterdam. All rights reserved.
                </p>
            </div>

            <!-- Social Brands -->
            <div
                class="footer-link mt-3 flex text-white space-x-2 justify-end mr-4"
            >
                <div class="mr-8">T: +31 6 15 31 75 25</div>
                <a
                    href="https://www.linkedin.com/company/waiveconsulting"
                    target="_blank"
                    class="footer-link text-white space-x-2"
                >
                    <img
                        src="../assets/linkedin.svg"
                        class="flex-shrink-0 w-6 h-6"
                        alt="LinkedIn"
                        style="
                            filter: brightness(0) invert(1);
                            transition: filter 0.3s;
                        "
                    />
                </a>
            </div>
            <!-- End Social Brands -->
        </div>
        <!-- End Grid -->
    </footer>
</template>

<script>
export default {
    name: "SiteFooter",
    // You can add JavaScript logic here if needed
};
</script>

<style scoped>
.footer-link:hover img {
    filter: brightness(0.5) sepia(1) hue-rotate(200deg) saturate(5); /* Adjust as needed */
}
</style>
