<template>
    <div class="background-gradient w-full">
        <!-- Icon Blocks -->
        <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div
                class="hidden lg:grid md:grid-cols-3 items-center gap-6 md:gap-10 py-6 sm:hidden"
            >
                <h2
                    class="block text-3xl font-semibold text-white font-header dark:text-white"
                >
                    We discover
                </h2>

                <h2
                    class="block text-3xl font-semibold text-white font-header dark:text-white"
                >
                    We transform
                </h2>
                <h2
                    class="block text-3xl font-semibold text-white font-header dark:text-white"
                >
                    We implement
                </h2>
            </div>
            <div
                class="grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-6 md:gap-10 py-6 mb-2"
            >
                <!-- Card -->
                <div
                    class="w-full h-full bg-white shadow-lg rounded-lg p-6 dark:bg-slate-900"
                >
                    <div class="flex items-center gap-x-4 mb-3">
                        <div class="inline-flex justify-center items-center">
                            <img
                                src="../assets/workshop-accent.png"
                                alt="plan"
                                class="w-8 h-8 sm:h-12 sm:w-12"
                            />
                        </div>
                        <div class="flex-shrink-0">
                            <h3
                                class="block text-base font-semibold text-primary font-header dark:text-white"
                            >
                                AI workshops for business
                            </h3>
                        </div>
                    </div>
                    <p
                        class="text-primary dark:text-gray-400 text-left font-body"
                    >
                        Empower your team with our interactive workshops,
                        designed to align your business goals with the
                        transformative potential of AI. <br /><br />We guide you
                        in identifying and prioritizing AI opportunities that
                        can drive significant business impact.
                    </p>
                </div>

                <!-- End Card -->

                <!-- Card -->
                <div
                    class="w-full h-full bg-white shadow-lg rounded-lg p-6 dark:bg-slate-900"
                >
                    <div class="flex items-center gap-x-4 mb-3">
                        <div class="inline-flex justify-center items-center">
                            <img
                                src="../assets/project-management-accent.png"
                                alt="plan"
                                class="w-8 h-8 sm:h-12 sm:w-12"
                            />
                        </div>
                        <div class="flex-shrink-0">
                            <h3
                                class="block text-base font-semibold text-primary dark:text-white font-header"
                            >
                                Dedicated AI officer
                            </h3>
                        </div>
                    </div>
                    <p
                        class="text-primary dark:text-gray-400 text-left font-body"
                    >
                        We work closely with your management team to integrate
                        AI into your business operations.
                        <br /><br />
                        Our approach focuses on enabling management to lead the
                        change, ensuring AI is embraced at all levels and
                        becomes a natural part of your business culture.
                    </p>
                </div>
                <!-- End Card -->

                <!-- Card -->

                <div
                    class="w-full h-full bg-white shadow-lg rounded-lg p-6 dark:bg-slate-900"
                >
                    <div class="flex items-center gap-x-4 mb-3">
                        <div class="inline-flex justify-center items-center">
                            <img
                                src="../assets/innovation-accent.png"
                                alt="plan"
                                class="w-8 h-8 sm:h-12 sm:w-12"
                            />
                        </div>
                        <div class="flex-shrink-0">
                            <h3
                                class="block text-base font-header font-semibold text-primary dark:text-white"
                            >
                                Custom AI solution
                            </h3>
                        </div>
                    </div>
                    <p
                        class="text-primary font-body dark:text-gray-40 text-left"
                    >
                        Beyond planning, we excel in the hands-on implementation
                        of generative AI solutions.
                        <br /><br />Our approach ensures seamless integration
                        into your business processes, with ongoing support to
                        track performance improvements and ROI.
                    </p>
                </div>

                <!-- End Card -->
            </div>
        </div>
        <!-- End Icon Blocks -->
    </div>
</template>

<script>
export default {
    name: "ServicesSection",
    // You can add JavaScript logic here if needed
};
</script>

<style scoped></style>
