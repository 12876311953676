<template>
    <!-- Testimonials -->
    <div
        class="max-w-[85rem] px-4 sm:px-6 lg:px-8 lg:py-14 mx-auto mt-16 mb-12"
    >
        <!-- Grid -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <!-- Card -->
            <div
                class="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700"
            >
                <div class="flex-auto p-4 md:p-6">
                    <img
                        src="../assets/parfumado-logo.png"
                        alt="Your Logo"
                        class="w-20 h-auto sm:w-28 mt-5"
                    />

                    <p
                        class="mt-3 py-6 sm:mt-6 font-body text-body text-gray-800 md:text-xl dark:text-white"
                    >
                        <em>
                            “ I was impressed by how the Waive workshop felt
                            like it was made just for us. <br /><br />They
                            nailed our needs and showed us how AI could
                            genuinely fit into our work life. ”
                        </em>
                    </p>
                </div>

                <div class="p-4 rounded-b-xl md:px-6">
                    <h3
                        class="font-semibold font-body text-gray-800 text-base sm:text-lg dark:text-gray-200"
                    >
                        Martijn van Rooy
                    </h3>
                    <p class="text-sm sm:text-base font-body text-gray-500">
                        Founder | Parfumado
                    </p>
                </div>
            </div>
            <!-- End Card -->

            <!-- Card -->
            <div
                class="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700"
            >
                <div class="flex-auto p-4 md:p-6">
                    <img
                        src="../assets/piekfijn-logo.png"
                        alt="Your Logo"
                        class="w-12 h-auto sm:w-16"
                    />

                    <p
                        class="mt-3 sm:mt-6 text-body font-body text-gray-800 md:text-xl dark:text-white"
                    >
                        <em>
                            “ The Waive team really opened our eyes in the
                            workshop. <br />
                            <br />
                            It's amazing to see a roadmap tailored just for us,
                            making AI less of a buzzword and more of a business
                            reality. ”
                        </em>
                    </p>
                </div>

                <div class="p-4 rounded-b-xl md:px-6">
                    <h3
                        class="text-base sm:text-lg font-body font-semibold text-gray-800 text-body dark:text-gray-200"
                    >
                        Thijs Bos
                    </h3>
                    <p
                        class="text-sm sm:text-base font-body text-gray-500 text-body"
                    >
                        Owner | Piekfijn
                    </p>
                </div>
            </div>
            <!-- End Card -->

            <!-- Card -->
            <div
                class="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700"
            >
                <div class="flex-auto p-4 md:p-6">
                    <img
                        src="../assets/west-coast-supply-group-logo.png"
                        alt="Your Logo"
                        class="w-16 h-auto sm:w-28"
                    />

                    <p
                        class="mt-3 sm:mt-7 text-base font-body text-gray-800 text-body md:text-xl dark:text-white"
                    >
                        <em>
                            " The inspiration session provided practical
                            insights into AI, sparking new ideas and awareness.
                            <br /><br />It was a valuable experience that
                            grounded AI in our everyday business context. "
                        </em>
                    </p>
                </div>

                <div class="p-4 rounded-b-xl md:px-6">
                    <h3
                        class="text-base sm:text-lg font-body font-semibold text-gray-800 text-body dark:text-gray-200"
                    >
                        Tom Teeuwisse
                    </h3>
                    <p
                        class="text-sm sm:text-base font-body text-body text-gray-500"
                    >
                        Head of Sales | West Coast Supply Group
                    </p>
                </div>
            </div>
            <!-- End Card -->
        </div>
        <!-- End Grid -->
    </div>
    <!-- End Testimonials -->
</template>

<script>
export default {
    name: "TestimonialSection",
    // You can add JavaScript logic here if needed
};
</script>
<style scoped></style>
