<template>
    <div class="spotted-gradient-background">
        <SiteHeader />
        <router-view></router-view>
        <SiteFooter />
    </div>
</template>

<script>
import SiteHeader from "./components/SiteHeader.vue";
import SiteFooter from "./components/SiteFooter.vue";

export default {
    name: "App",
    components: {
        SiteHeader,
        SiteFooter,
    },
    watch: {
        $route(to) {
            if (to.hash) {
                const section = document.querySelector(to.hash);
                if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                }
            }
        },
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
