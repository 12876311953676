<template>
    <div class="spotted-gradient-background">
        <HeroSection />
        <!-- <ClientSection /> -->
        <TestimonialSection />
        <PainPointsSection />
        <BlogSection />
        <ServicesSection />
        <AboutUsSection />
        <ContactSection />
        <SiteFooter />
    </div>
</template>

<script>
import HeroSection from "./components/HeroSection.vue";
// import ClientSection from "./components/ClientSection.vue";
import TestimonialSection from "./components/TestimonialSection.vue";
import PainPointsSection from "./components/PainPointsSection.vue";
import BlogSection from "./components/BlogSection.vue";
import AboutUsSection from "./components/AboutUsSection.vue";
import ServicesSection from "./components/ServicesSection.vue";
import ContactSection from "./components/ContactSection.vue";

export default {
    name: "HomePage",
    components: {
        HeroSection,
        // ClientSection,
        TestimonialSection,
        PainPointsSection,
        BlogSection,
        AboutUsSection,
        ServicesSection,
        ContactSection,
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
