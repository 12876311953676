<template>
    <div class="bg-gray w-full">
        <!-- Features -->
        <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div class="aspect-w-16 aspect-h-6 mt-12">
                <img
                    class="w-full object-cover h-80 rounded-xl"
                    src="../assets/image00027.jpg"
                    alt="Image Description"
                />
            </div>

            <!-- Grid -->
            <div
                class="mt-5 lg:mt-16 grid lg:grid-cols-3 gap-8 lg:gap-12 mb-10"
            >
                <div class="lg:col-span-1">
                    <h2
                        class="font-bold font-header text-2xl md:text-3xl text-primary dark:text-gray-200 text-left"
                    >
                        We know the journey to AI has its hurdles
                    </h2>
                    <p
                        class="mt-2 font-body md:mt-4 text-secondary text-left py-4"
                    >
                        Navigating the complexity of AI can be daunting.
                        <br /><br />Waive simplifies this journey by addressing
                        common obstacles with clear, actionable solutions.
                    </p>
                </div>
                <!-- End Col -->

                <div class="lg:col-span-2">
                    <div class="grid sm:grid-cols-2 gap-8 md:gap-12">
                        <!-- Icon Block -->
                        <div class="flex gap-x-5">
                            <img
                                src="../assets/potential-accent.png"
                                alt="Your Logo"
                                class="w-8 h-8 sm:h-12 sm:w-12"
                            />
                            <div class="grow">
                                <h3
                                    class="text-lg font-header font-semibold text-primary dark:text-white"
                                >
                                    Recognizing AI's potential
                                </h3>
                                <p
                                    class="mt-1 text-secondary font-body dark:text-gray-400"
                                >
                                    Through inspiration sessions and workshops,
                                    we enable businesses to uncover and harness
                                    AI's transformative power.
                                </p>
                            </div>
                        </div>
                        <!-- End Icon Block -->

                        <!-- Icon Block -->
                        <div class="flex gap-x-5">
                            <img
                                src="../assets/networking-accent.png"
                                alt="Your Logo"
                                class="w-8 h-8 sm:h-12 sm:w-12"
                            />
                            <div class="grow">
                                <h3
                                    class="text-lg font-header font-semibold text-primary dark:text-white"
                                >
                                    Coordinating AI use
                                </h3>
                                <p
                                    class="mt-1 font-body text-secondary dark:text-gray-400"
                                >
                                    Our workshops provide a cohesive strategy,
                                    channeling random AI tool use into a
                                    systematic edge.
                                </p>
                            </div>
                        </div>
                        <!-- End Icon Block -->

                        <!-- Icon Block -->
                        <div class="flex gap-x-5">
                            <img
                                src="../assets/implementation-accent.png"
                                alt="Your Logo"
                                class="w-8 h-8 sm:h-12 sm:w-12"
                            />
                            <div class="grow">
                                <h3
                                    class="text-lg font-header font-semibold text-primary dark:text-white"
                                >
                                    AI implementation expertise
                                </h3>
                                <p
                                    class="mt-1 font-body text-secondary dark:text-gray-400"
                                >
                                    Waive ensures direct, seamless AI tool
                                    implementation, enhancing utility and
                                    integration for your business.
                                </p>
                            </div>
                        </div>
                        <!-- End Icon Block -->

                        <!-- Icon Block -->
                        <div class="flex gap-x-5">
                            <img
                                src="../assets/security-accent.png"
                                alt="Your Logo"
                                class="w-8 h-8 sm:h-12 sm:w-12"
                            />
                            <div class="grow">
                                <h3
                                    class="text-lg font-header font-semibold text-primary dark:text-white"
                                >
                                    AI Security
                                </h3>
                                <p
                                    class="mt-1 font-body text-secondary dark:text-gray-400"
                                >
                                    We establish secure AI environments and
                                    robust data policies, protecting your
                                    business from privacy and security risks.
                                </p>
                            </div>
                        </div>
                        <!-- End Icon Block -->
                    </div>
                </div>
                <!-- End Col -->
            </div>
            <!-- End Grid -->
        </div>
        <!-- End Features -->
    </div>
</template>

<script>
export default {
    name: "PainPointsSection",
    // You can add JavaScript logic here if needed
};
</script>

<style scoped>
.grow {
    text-align: left;
}
</style>
