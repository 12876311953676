<template>
    <div class="not-found">
        <h1 class="text-xl font-header text-tertiary mt-10">404</h1>
        <p class="text-xl font-header text-tertiary">Page Not Found</p>
    </div>
</template>

<script>
export default {
    name: "NotFound",
};
</script>

<style scoped>
.not-found {
    text-align: center;
    margin-top: 50px;
    min-height: 82vh;
}
</style>
