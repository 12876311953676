<template>
    <!-- Blog Article -->
    <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
        <div
            class="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 text-xl text-left lg:gap-x-6 lg:gap-x-12"
        >
            <!-- Content -->
            <div class="lg:col-span-2 container mx-auto p-4">
                <img
                    src="../assets/empty_value_feasibility.png"
                    alt="Blog2Image1"
                    class="mt-16 mb-4 max-w-2xl mx-auto border"
                />
                <p class="mb-8 text-sm text-gray-600 text-center">
                    Identify use cases with the value vs feasibility matrix
                </p>
                <h1 class="text-5xl mt-8 font-bold mb-4">
                    A step-by-step guide to identifying valuable AI use cases
                </h1>
                <p class="mb-4">
                    The advent of Generative AI, like ChatGPT, has completely
                    changed the playing field. You no longer need to create
                    models from scratch; you can simply integrate an existing
                    one. This opens doors to unprecedented possibilities and
                    enhancements.
                </p>
                <p class="mb-4">
                    Ok, so you’re interested in AI. How to get started?
                </p>
                <p class="mb-4">
                    The success of integrating AI heavily depends on choosing
                    the right AI use case. The AI projects that fail are often
                    those that should never have been initiated.
                </p>
                <p class="mb-4">
                    Like with a lot of things in business:
                    <strong
                        >you start with the problem, not with the
                        solution</strong
                    >.
                </p>
                <p class="mb-4">
                    This blog provides a practical guide for businesses to
                    identify the right AI projects to start on.
                </p>
                <p class="mb-4">I will cover:</p>
                <ul class="custom-bullet-list">
                    <li class="mb-4">What is an AI use case?</li>
                    <li class="mb-4">What makes a good AI use case?</li>
                    <li class="mb-4">
                        The AI ideation method: Scope, Ideate, Evaluate
                    </li>
                    <li class="mb-4">Conclusion</li>
                </ul>

                <p class="mb-4">
                    Please note that this article is focused on Generative AI. I
                    use the term AI for Generative AI for simplicity.
                </p>

                <p class="mb-4 text-blue">
                    I use practical examples of a fictional brewery BeerCompany
                    and write them in italics.
                </p>

                <h2 class="text-3xl mt-8 font-semibold mb-3">
                    What is an AI use case?
                </h2>
                <p class="mb-4">
                    AI is a general purpose technology, just like electricity.
                    Electricity can be used for uncountable cases: toast bread,
                    trade stocks, drive a car, well you name it. With AI, the
                    options are truly limitless and growing at an unprecedented
                    speed. To have a peace of mind in this chaos of possible use
                    cases I find it useful to categorize these use cases since
                    they have different characteristics.
                </p>

                <h3 class="text-2xl mt-8 font-semibold mb-3">
                    3 use case categories:
                </h3>
                <ol>
                    <li class="mb-4">
                        <p class="mb-2">
                            <strong>Employee augmentation</strong>
                        </p>
                        <p class="mb-2">At the employee level, ready to use.</p>
                        <p class="mb-2">
                            Characteristics: lowest risk, but also least amount
                            of efficiency gain. Implementing is more about
                            education and change management.
                        </p>
                        <p class="mb-2 text-blue">
                            Example: A BeerCompany sales employee uses ChatGPT
                            for market analysis to quickly scan over all
                            retailers in a region.
                        </p>
                    </li>
                    <li class="mb-4">
                        <p class="mb-2">
                            <strong>Automating business processes</strong>
                        </p>
                        <p class="mb-2">
                            At the business process level, requires building
                            some software.
                        </p>
                        <p class="mb-2">
                            Characteristics: greater possible efficiency gain,
                            but also has more risk.
                        </p>
                        <p class="mb-2 text-blue">
                            Example: a tool that allows business analysts to
                            easily search through all historic brewery process
                            documentation.
                        </p>
                    </li>
                    <li class="mb-4">
                        <p class="mb-2">
                            <strong>Inventing new products or services</strong>
                        </p>
                        <p class="mb-2">
                            At the strategic and customer level, requires
                            building some software.
                        </p>
                        <p class="mb-2">
                            Characteristics: not about efficiency, but about
                            more revenue, new revenue streams or new business
                            models.
                        </p>
                        <p class="mb-2 text-blue">
                            Example: BeerCompany creates an online tool with
                            AI-enhanced personalized beer chooser.
                        </p>
                    </li>
                </ol>

                <h2 class="text-3xl mt-8 font-semibold mb-3">
                    What makes a good AI use case?
                </h2>

                <p class="mb-4">
                    Great, now we know what we mean with an AI use case. Before
                    we delve into where to look for them, it's crucial to
                    understand the basis on how they are evaluated.
                </p>
                <p class="mb-4">
                    A use case essentially has two core metrics:
                    <strong>Value and Feasibility</strong>.
                </p>
                <p class="mb-4">
                    While value is important, you always want your idea to have
                    high feasibility. There are three reasons for this:
                </p>

                <ol>
                    <li class="mb-4">
                        A success at the start of your AI journey is necessary
                        for further management support.
                    </li>
                    <li class="mb-4">
                        They involve less risk, and get done faster and cheaper.
                    </li>
                    <li class="mb-4">
                        You are still learning how to implement and integrate AI
                        projects.
                    </li>
                </ol>

                <p class="mb-4">
                    Assessing value and feasibility is not an exact science,
                    especially not in the ideation phase. But it’s crucial that
                    you can come to a approximation as a team, at least as a
                    ranking. Business professionals are typically quite adept at
                    assessing the value of a use case. Feasibility, being mostly
                    a technical aspect, requires a specialist for an accurate
                    evaluation.
                </p>

                <p class="mb-4">
                    There are the subcriteria of each that can help you
                    assessing the two core metrics. Later on, if you dive deeper
                    in the most potential use case to refine it to a business
                    case, you can use this list for a more thorough assessment.
                </p>
                <p class="mb-4"><strong>Subcriteria value:</strong></p>
                <ul class="custom-bullet-list">
                    <li class="mb-4">
                        High potential for cost reduction or profit increase
                    </li>
                    <li class="mb-4">Clear value proposition</li>
                    <li class="mb-4">Strategic alignment</li>
                    <li class="mb-4">Measurable results</li>
                </ul>
                <p class="mb-4"><strong>Subcriteria feasibility:</strong></p>
                <ul class="custom-bullet-list">
                    <li class="mb-4">Available technology capabilities</li>
                    <li class="mb-4">Data availability and quality</li>
                    <li class="mb-4">Compatibility with existing systems</li>
                    <li class="mb-4">Privacy and security</li>
                    <li class="mb-4">Available expertise</li>
                    <li class="mb-4">Cost</li>
                </ul>

                <p class="mb-4">
                    Now that you know what encompasses a good AI use case, let’s
                    move to the exciting part: brainstorming!
                </p>

                <img
                    src="../assets/midjrouney_beercompany.jpg"
                    alt="Blog2Image2"
                    class="mt-16 mb-4 max-w-2xl mx-auto center"
                />
                <p class="text-sm text-gray-600 mb-8 text-center">
                    A look inside the brewery of fictional BeerCompany
                </p>
                <h2 class="text-3xl font-bold mt-8 mb-3">AI ideation method</h2>
                <p class="mb-4 text-blue">
                    Again, in blue, we will provide an ongoing example of a
                    fictitious brewery, BeerCompany.
                </p>
                <p class="mb-4">This method involves three steps:</p>
                <ol>
                    <li class="mb-4">Scope your brainstorm</li>
                    <li class="mb-4">Brainstorm</li>
                    <li class="mb-4">
                        Evaluate with the value vs feasibility matrix
                    </li>
                </ol>

                <h3 class="text-2xl mt-8 font-semibold mb-3">
                    1. Scope your brainstorm
                </h3>

                <p class="mb-4">
                    It's important to have a clear picture of where your company
                    creates the most value and where their pain points lie. To
                    name an example, automatically generating monthly financial
                    reports can be very useful but might not be a priority.
                </p>
                <p class="mb-4">
                    Note that this scoping works best for the Automating
                    business processes category. For ideating truly disruptive
                    AI applications, you probably want to look outside your
                    value chain.
                </p>
                <ul class="custom-bullet-list">
                    <li>
                        <p class="mb-4">
                            <strong>Identify the primary activities:</strong>
                        </p>
                        <p class="mb-4">
                            These are the steps that directly contribute to
                            creating your product or service.
                        </p>
                        <p class="mb-4 text-blue">
                            For BeerCompany, the primary activities include
                            ingredient selection, the brewing process,
                            packaging, marketing and distribution, and customer
                            service.
                        </p>
                    </li>
                    <li>
                        <p class="mb-4">
                            <strong>Identify supporting activities:</strong>
                        </p>
                        <p class="mb-4">
                            These are the steps essential for the effective
                            functioning of your primary activities.
                        </p>
                        <p class="mb-4 text-blue">
                            This includes the development of new beer recipes,
                            quality control, supplier management, and IT
                            infrastructure management.
                        </p>
                    </li>
                    <li>
                        <p class="mb-4">
                            <strong>Analyze where most value Is added:</strong>
                        </p>
                        <p class="mb-4">
                            This could be a department that works exceptionally
                            efficiently or a step in the process where your
                            customers attach great value.
                        </p>
                        <p class="mb-4 text-blue">
                            At BeerCompany, significant value is added in the
                            beer quality (brewing process), marketing, and
                            supplier relationships.
                        </p>
                    </li>
                    <li>
                        <p class="mb-4">
                            <strong
                                >Identify pain points and opportunities:</strong
                            >
                        </p>
                        <p class="mb-4">
                            Look for areas above where processes are inefficient
                            or where customer satisfaction can be improved.
                            These are key indicators of where an AI solution can
                            add value.
                        </p>
                        <p class="mb-4 text-blue">
                            For example, within marketing, inefficiencies may
                            occur in the design process, responding to customer
                            feedback, and keeping up with market trends.
                        </p>
                    </li>
                </ul>

                <p class="mb-4">
                    This analysis provides a solid foundation for your
                    brainstorming session, offering a clear overview of where
                    your company stands and potential opportunities for AI
                    integration. With this information in hand, you are better
                    prepared to focus on brainstorming AI use cases that can
                    truly make a difference for your business.
                </p>

                <img
                    src="../assets/fict_beer.jpg"
                    alt="Blog2Image3"
                    class="mt-16 mb-4 max-w-2xl mx-auto center"
                />
                <p class="text-sm text-gray-600 mb-8 text-center">
                    The latest beer of BeerCompany
                </p>

                <h3 class="text-2xl mt-8 font-semibold mb-3">
                    2. Brainstorm by core function
                </h3>

                <ul class="custom-bullet-list">
                    <li>
                        <p class="mb-4">
                            <strong
                                >Focus on specific questions in the
                                brainstorm</strong
                            >
                        </p>
                        <p class="mb-4">
                            Concentrate on specific questions relevant to each
                            core function. Pay attention to pain points and how
                            you can address them.
                        </p>
                        <p class="mb-4 text-blue">
                            For marketing, BeerCompany asks these questions
                            while brainstorming:
                        </p>
                        <ol>
                            <li class="text-blue">
                                How can we better understand and respond to
                                customer behavior?
                            </li>
                            <li class="text-blue">
                                In what ways can we personalize and optimize our
                                marketing campaigns?
                            </li>
                            <li class="text-blue mb-4">
                                Is there a possibility to analyze real-time
                                market trends and respond quickly?
                            </li>
                        </ol>
                    </li>

                    <li>
                        <p class="mb-4">
                            <strong>Brainstorm &#129504; &#127786;</strong>
                        </p>
                        <p class="mb-4">
                            Give everyone individual a couple of minutes and
                            just go. Free format. Write it on post-its.
                        </p>
                        <p class="mb-4 text-blue">
                            Brainstorm use cases for BeerCompany:
                        </p>
                        <ol>
                            <li class="text-blue">
                                Automatically analyze customer feedback and
                                market trends to adapt beer recipes
                            </li>
                            <li class="text-blue">
                                An AI-driven chatbot for retailers
                            </li>
                            <li class="text-blue">
                                Optimizing social media ad placements
                            </li>
                            <li class="text-blue">
                                Set up a competitor analysis
                            </li>
                            <li class="text-blue">
                                Creating personalized marketing campaigns
                            </li>
                            <li class="text-blue mb-4">
                                Real-time adjustment of pricing strategies
                            </li>
                        </ol>
                    </li>
                </ul>

                <h3 class="text-2xl mt-8 font-semibold mb-3">
                    Evaluation: value vs feasibility matrix
                </h3>

                <p class="mb-4">
                    Great. Now we have a list of potential business cases! Now
                    discuss your ideas with the group and come to a consensus on
                    where to plot them on the value vs feasibility matrix.
                </p>

                <p class="mb-4">
                    The value vs feasibility matrix is a tool to prioritize your
                    ideas. All ideas will land in one of 4 quadrants:
                </p>
                <ul class="custom-bullet-list">
                    <li class="mb-4">
                        <strong>Sweet spot:</strong> high value - high
                        feasibility
                    </li>
                    <li class="mb-4">
                        <strong>Easy win:</strong> low value - high feasibility
                    </li>
                    <li class="mb-4">
                        <strong>Long-term goals:</strong> high value - low
                        feasibility
                    </li>
                    <li class="mb-4">
                        <strong>Low priority:</strong> low value - low
                        feasibility
                    </li>
                </ul>

                <p class="mb-4">
                    Realize that you don’t have the full information. So don’t
                    be too precise. For example, you probably don’t know yet how
                    valuable an AI-driven competitor analysis will be. Or
                    optimizing ad placements with AI is very hard to build
                    yourself, but probably there is a tool available but you
                    don’t know if that suits your needs. The goal here to come
                    up with one or two sweet spot ideas. An additional goal is
                    to train you to think about the benefits and risks of AI
                    solutions.
                </p>

                <img
                    src="../assets/valuevsfeasibility.png"
                    alt="Blog2Image3"
                    class="mt-16 mb-4 max-w-2xl mx-auto center"
                />
                <p class="text-sm text-gray-600 mb-8 text-center">
                    Value vs feasibility matrix with examples
                </p>

                <p class="mb-4 text-blue">
                    We've identified several promising AI initiatives for
                    BeerCompany! At this stage, the analysis of customer
                    feedback emerges as the top choice. However, the competitor
                    analysis might be a more suitable initial project,
                    considering its high feasibility. Additionally, the client
                    chatbot stands out as another compelling idea.
                </p>
                <p class="mb-4 text-blue">
                    For BeerCompany, the advisable next steps are:
                </p>
                <ol>
                    <li class="mb-4 text-blue">
                        Dive deeper and develop detailed business cases for
                        these three ideas
                    </li>
                    <li class="mb-4 text-blue">
                        Sprint towards your first measurable AI application
                    </li>
                </ol>
                <p class="mb-4 text-blue">
                    Beyond these immediate steps, BeerCompany’s broader AI
                    integration strategy should include:
                </p>
                <ol>
                    <li class="mb-4 text-blue">
                        Establish an AI committee to gather insights from the
                        ground up
                    </li>
                    <li class="mb-4 text-blue">
                        Create company-wide AI guidelines
                    </li>
                    <li class="mb-4 text-blue">
                        Invest in training and education
                    </li>
                    <li class="mb-4 text-blue">
                        Expanding to other AI applications
                    </li>
                </ol>

                <h2 class="text-3xl font-bold mt-8 mb-3">Conclusion</h2>
                <p class="mb-4">
                    As we wrap up our exploration of AI in business, it's clear
                    that the key to successful AI integration lies in smartly
                    identifying and evaluating use cases. For companies like
                    BeerCompany, and indeed any business eager to dive into AI,
                    it's about more than just adopting new technology. It’s
                    about strategically embedding AI into their core operations.
                </p>
                <p class="mb-8">
                    This involves not just recognizing where AI can add
                    significant value, but also understanding the practical
                    aspects of its implementation. By effectively assessing both
                    the value and feasibility of AI applications, businesses are
                    well-positioned to leverage AI’s transformative potential.
                </p>
                <p class="mt-16 mb-4">Thank you for reading!</p>
                <p class="mb-32">Sophron Vermeij</p>
                <p class="mt-8 mb-4">
                    At Waive, we understand the unique challenges every
                    organization faces when integrating AI. We help companies
                    jointly discover AI and successfully integrate it into their
                    business processes. Only with AI will you be well-prepared
                    for the future. And it's not just about technology; it's
                    about transforming processes and seizing new opportunities
                    as well
                </p>
                <p class="mb-4">
                    The future won't wait, and the development of AI doesn't
                    stand still. Start exploring the possibilities today.
                    Contact me for a free exploratory conversation and discover
                    how Waive can guide you in taking this important step into
                    the future.
                </p>
                <!-- ... More content ... -->
            </div>
            <!-- End Content -->

            <!-- Sidebar -->
            <div
                class="lg:col-span-1 lg:w-full lg:h-full lg:bg-gradient-to-r lg:from-gray-50 lg:via-transparent lg:to-transparent dark:from-slate-800"
            >
                <div class="sticky top-0 start-0 py-8 lg:ps-4 lg:ps-8">
                    <!-- Avatar Media -->
                    <div
                        class="group flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 dark:border-gray-700"
                    >
                        <h3
                            class="group-hover:text-gray-600 text-l font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200"
                        >
                            Our other blogs
                        </h3>
                    </div>
                    <!-- End Avatar Media -->

                    <div class="space-y-6">
                        <!-- Media -->
                        <router-link
                            class="group flex items-center gap-x-6"
                            to="/blog1"
                        >
                            <div class="grow">
                                <span
                                    class="text-sm font-bold text-gray-800 group-hover:text-blue-600 dark:text-gray-200 dark:group-hover:text-blue-500"
                                >
                                    7 smart strategies to prepare for an
                                    AI-driven future
                                </span>
                            </div>

                            <div
                                class="flex-shrink-0 relative rounded-lg overflow-hidden w-20 h-20"
                            >
                                <img
                                    class="w-full h-full absolute top-0 start-0 object-cover rounded-lg"
                                    src="../assets/1706130741282.jpg"
                                    alt="Blog1Image1"
                                />
                            </div>
                        </router-link>
                        <!-- End Media -->

                        <!-- Media -->
                        <router-link
                            class="group flex items-center gap-x-6"
                            to="/blog2"
                        >
                            <div class="grow">
                                <span
                                    class="text-sm font-bold text-gray-800 group-hover:text-blue-600 dark:text-gray-200 dark:group-hover:text-blue-500"
                                >
                                    A step-by-step guide to identifying valuable
                                    AI use cases
                                </span>
                            </div>

                            <div
                                class="flex-shrink-0 relative rounded-lg overflow-hidden w-20 h-20"
                            >
                                <img
                                    class="w-full h-full absolute top-0 start-0 object-cover rounded-lg"
                                    src="../assets/midjrouney_beercompany.jpg"
                                    alt="Blog2Image1"
                                />
                            </div>
                        </router-link>
                        <!-- End Media -->

                        <!-- Media -->
                        <!-- <a class="group flex items-center gap-x-6" href="#">
                            <div class="grow">
                                <span
                                    class="text-sm font-bold text-gray-800 group-hover:text-blue-600 dark:text-gray-200 dark:group-hover:text-blue-500"
                                >
                                    7 Principles of Icon Design
                                </span>
                            </div>

                            <div
                                class="flex-shrink-0 relative rounded-lg overflow-hidden w-20 h-20"
                            >
                                <img
                                    class="w-full h-full absolute top-0 start-0 object-cover rounded-lg"
                                    src="https://images.unsplash.com/photo-1586232702178-f044c5f4d4b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                                    alt="Image Description"
                                />
                            </div>
                        </a> -->
                        <!-- End Media -->
                    </div>
                </div>
            </div>
            <!-- End Sidebar -->
        </div>
    </div>
    <!-- End Blog Article -->
</template>

<script>
export default {
    name: "BlogPage2",
    // You can add JavaScript logic here if needed
};
</script>

<style scoped>
ol {
    list-style-type: decimal;
    padding-left: 32px;
}

.custom-bullet-list {
    list-style-type: disc;
    padding-left: 32px;
}

.text-blue {
    color: blue;
}
</style>
