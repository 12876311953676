<template>
    <!-- Hero -->
    <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
        <!-- Grid -->
        <div
            class="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center mt-16"
        >
            <div
                class="flex flex-col items-center md:items-start md:justify-start mb-16"
            >
                <div class="w-full flex flex-col items-center mb-4">
                    <a href="#" aria-label="Brand">
                        <img
                            src="../assets/Logo_paars_2.svg"
                            alt="Logo"
                            class="h-40 ml-4"
                        />
                    </a>
                    <h1
                        class="block text-xl font-header text-left text-tertiary imtsm:text-2xl lg:text-3xl lg:leading-tight dark:text-white"
                    >
                        Your AI journey, our expertise
                    </h1>
                </div>

                <p
                    class="mt-12 text-3xl text-center text-primary dark:text-gray-400"
                >
                    Accelerate your growth with AI solutions that drive real
                    results.
                </p>

                <!-- Buttons -->
                <div
                    class="mt-16 grid gap-5 w-full sm:inline-flex justify-center"
                >
                    <a
                        class="py-3 px-4 inline-flex justify-center items-center gap-x- text-m font-semibold font-body rounded-lg border border-transparent bg-primary text-white hover:bg-accent disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        href="tel:+31615317525"
                    >
                        Call us
                        <svg
                            class="flex-shrink-0 w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="m9 18 6-6-6-6" />
                        </svg>
                    </a>
                    <a
                        class="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-m font-medium font-body rounded-lg border border-gray-200 bg-white hover:no-border hover:bg-accent hover:text-white textsecondary shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        href="mailto:sophron@waiveconsulting.nl"
                    >
                        Mail us
                    </a>
                </div>
                <!-- End Buttons -->
            </div>
            <!-- End Col -->

            <div class="relative ms-4 p-12">
                <img
                    class="w-full rounded-xl"
                    src="../assets/image00013.jpg"
                    alt="Image Description"
                />
                <div
                    class="absolute inset-0 -z-[1] bg-gradient-to-tr from-gray-200 via-white/0 to-white/0 w-full h-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6 dark:from-slate-800 dark:via-slate-900/0 dark:to-slate-900/0"
                ></div>
            </div>
            <!-- End Col -->
        </div>
        <!-- End Grid -->
    </div>
    <!-- End Hero -->
</template>

<script>
export default {
    name: "HeroSection",
    // You can add JavaScript logic here if needed
};
</script>

<style scoped></style>
