<template>
    <!-- ========== HEADER ========== -->
    <header class="header background-gradient text-sm py-3 sm:py-2 z-50">
        <div
            class="max-w-[85rem] mx-auto flex justify-between items-center w-full"
        >
            <router-link
                to="/"
                aria-label="Brand"
                class="ml-4 lg:ml-8 py-2 md:py-0"
            >
                <img
                    src="../assets/Waive white logo.svg"
                    alt="Logo"
                    class="h-12"
                />
            </router-link>
            <nav class="mr-4 sm:mr-8 lg:mr-16" aria-label="Global">
                <div class="flex items-center justify-between">
                    <div class="sm:hidden">
                        <button
                            type="button"
                            class="hs-collapse-toggle w-9 h-9 flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-white/20 text-white hover:border-white/40 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            data-hs-collapse="#navbar-collapse-with-animation"
                            aria-controls="navbar-collapse-with-animation"
                            aria-label="Toggle navigation"
                        >
                            <svg
                                class="hs-collapse-open:hidden flex-shrink-0 w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <line x1="3" x2="21" y1="6" y2="6" />
                                <line x1="3" x2="21" y1="12" y2="12" />
                                <line x1="3" x2="21" y1="18" y2="18" />
                            </svg>
                            <svg
                                class="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div
                    id="navbar-collapse-with-animation"
                    class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block"
                >
                    <div
                        class="flex flex-col gap-y-4 gap-x-0 mt-4 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7"
                    >
                        <router-link
                            class="font-body text-xl text-white/[.8] hover:text-accent sm:py-6"
                            to="/"
                            >Home</router-link
                        >
                        <router-link
                            class="font-body text-xl text-white/[.8] hover:text-accent sm:py-6"
                            to="#blogSection"
                            @click="navigateToSection('blogSection')"
                            >Blog</router-link
                        >
                        <router-link
                            class="font-body text-xl text-white/[.8] hover:text-accent sm:py-6"
                            to="#contactSection"
                            @click="navigateToSection('contactSection')"
                            >Contact</router-link
                        >
                    </div>
                </div>
            </nav>
        </div>
    </header>
    <!-- ========== END HEADER ========== -->
</template>

<script>
export default {
    name: "SiteHeader",
    methods: {
        navigateToSection(sectionId) {
            if (this.$route.path !== "/") {
                // Navigate to the homepage first
                this.$router.push({ path: "/", hash: sectionId });
            } else {
                // Directly change the hash if already on the homepage
                window.location.hash = sectionId;
            }
        },
    },
    // Rest of your component
};
</script>

<style scoped></style>
