<template>
    <!-- Contact Us -->
    <div class="background-gradient w-full" id="contactSection">
        <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 py-12 lg:py-20 mx-auto">
            <div class="max-w-xl mx-auto">
                <div class="text-center">
                    <h1
                        class="text-3xl font-header font-bold text-white sm:text-4xl dark:text-white"
                    >
                        Contact us
                    </h1>
                    <p
                        class="mt-1 font-body text-xl text-white dark:text-gray-400"
                    >
                        We'd love to talk about how we can help you.
                    </p>
                </div>
            </div>

            <div class="mt-12 max-w-lg mx-auto">
                <!-- Card -->
                <div
                    class="flex flex-colborder justify-center bg-white rounded-xl p-4 sm:p-6 lg:p-8 dark:border-gray-700"
                >
                    <form
                        name="contactForm"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        method="post"
                        @submit="handleSubmit"
                    >
                        <input
                            type="hidden"
                            name="form-name"
                            value="contactForm"
                        />
                        <div class="grid gap-4 lg:gap-6">
                            <!-- Grid -->
                            <div
                                class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6"
                            >
                                <div>
                                    <label
                                        for="name"
                                        class="font-body block mb-2 text-sm text-secondary font-medium dark:text-white"
                                        >Name</label
                                    >
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="font-body text-secondary py-3 px-4 block w-full border rounded-lg text-sm focus:border-accent focus:ring-accent disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                                    />
                                </div>

                                <div>
                                    <label
                                        for="email"
                                        class="font-body block mb-2 text-sm text-secondary font-medium dark:text-white"
                                        >Email</label
                                    >
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="font-body text-secondary py-3 px-4 block w-full border rounded-lg text-sm focus:border-accent focus:ring-accent disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                                    />
                                </div>
                            </div>
                            <!-- End Grid -->

                            <div>
                                <label
                                    for="message"
                                    class="font-body block mb-2 text-sm text-secondary font-medium dark:text-white"
                                    >Message</label
                                >
                                <textarea
                                    id="message"
                                    name="message"
                                    rows="4"
                                    class="font-body text-secondary py-3 px-4 block w-full border rounded-lg text-sm focus:border-accent focus:ring-accent disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                                ></textarea>
                            </div>
                        </div>
                        <!-- End Grid -->

                        <div class="mt-6 grid">
                            <button
                                type="submit"
                                class="w-full py-3 px-4 font-body inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-accent disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            >
                                Send message
                            </button>
                        </div>

                        <div class="mt-3 text-center">
                            <p class="text-sm text-primary font-body">
                                We'll get back to you in 1-2 business days.
                            </p>
                        </div>
                        <div v-if="formSubmitted" class="mt-4 text-center">
                            <p class="text-lg text-green-600 font-medium">
                                Thank you! Your message has been sent
                                successfully.
                            </p>
                        </div>
                        <div v-if="formError" class="mt-4 text-center">
                            <p class="text-lg text-red-600 font-medium">
                                An error occurred. Please try again later.
                            </p>
                        </div>
                    </form>
                </div>
                <!-- End Card -->
            </div>
        </div>
        <!-- End Contact Us -->
    </div>
</template>

<script>
export default {
    name: "ContactSection",
    data() {
        return {
            formSubmitted: false,
            formError: false,
        };
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();

            let formData = new FormData(event.target);

            fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(formData).toString(),
            })
                .then((response) => {
                    if (response.ok) {
                        this.formSubmitted = true;
                    } else {
                        this.formError = true;
                    }
                })
                .catch(() => (this.formError = true));
        },
    },
};
</script>

<style scoped></style>
