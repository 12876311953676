<template>
    <!-- Card Blog -->
    <div
        id="blogSection"
        class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto mb-10"
    >
        <!-- Title -->
        <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
            <h2
                class="text-2xl font-header font-bold md:text-4xl md:leading-tight text-primary dark:text-white"
            >
                Industry insights
            </h2>
        </div>
        <!-- End Title -->

        <!-- Grid -->
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <!-- Card -->
            <!-- Placeholder for the first column -->

            <!-- <router-link
                class="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                to="/blog1"
            >
                <div class="aspect-w-16 aspect-h-11">
                    <img
                        class="w-full object-cover rounded-xl"
                        src="https://images.unsplash.com/photo-1633114128174-2f8aa49759b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                        alt="Image Description"
                    />
                </div>
                <div class="my-6">
                    <h3
                        class="text-xl font-semibold text-primary dark:text-gray-300 dark:group-hover:text-white"
                    >
                        Announcing a free plan for small teams
                    </h3>
                    <p class="mt-5 text-secondary dark:text-gray-400">
                        At Wake, our mission has always been focused on bringing
                        openness.
                    </p>
                </div>
                <div class="mt-auto flex items-center gap-x-3">
                    <img
                        class="w-8 h-8 rounded-full"
                        src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
                        alt="Image Description"
                    />
                    <div>
                        <h5 class="text-sm text-secondary dark:text-gray-200">
                            By Lauren Waller
                        </h5>
                    </div>
                </div>
            </router-link> -->
            <!-- End Card -->
            <!-- Empty spacer for large screens -->
            <!-- Card -->
            <router-link
                class="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                to="/blog1"
            >
                <div class="aspect-w-16 aspect-h-11">
                    <img
                        class="w-full object-cover rounded-xl"
                        src="../assets/1706130741282.jpg"
                        alt="Image Description"
                    />
                </div>
                <div class="my-6">
                    <h3
                        class="text-xl font-semibold text-primary dark:text-gray-300 dark:group-hover:text-white"
                    >
                        7 smart strategies to prepare for an AI-driven future
                    </h3>
                    <p class="mt-5 text-secondary dark:text-gray-400">
                        This blog explores how businesses can prepare for the
                        future of AI through effective change management,
                        without losing the strength of today
                    </p>
                </div>
                <div class="mt-auto flex items-center gap-x-3">
                    <img
                        class="image-crop"
                        src="../assets/foto_died_website.jpg"
                        alt="Image Description"
                    />
                    <div>
                        <h5 class="text-sm text-secondary dark:text-gray-200">
                            By Diederik Lankhof
                        </h5>
                    </div>
                </div>
            </router-link>
            <!-- End Card -->

            <!-- Card -->
            <router-link
                class="group flex flex-col h-full border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                to="/blog2"
            >
                <div class="aspect-w-16 aspect-h-11">
                    <img
                        class="w-full object-cover rounded-xl"
                        src="../assets/valuevsfeasibility.png"
                        alt="valuevsfeasibility"
                    />
                </div>
                <div class="my-6">
                    <h3
                        class="text-xl font-semibold text-primary dark:text-gray-300 dark:group-hover:text-white"
                    >
                        A step-by-step guide to identifying valuable AI use
                        cases
                    </h3>
                    <p class="mt-5 text-secondary dark:text-gray-400">
                        The success of integrating AI heavily depends on finding
                        the right AI use case. This is a guide to get you
                        started.
                    </p>
                </div>
                <div class="mt-auto flex items-center gap-x-3">
                    <img
                        class="image-crop"
                        src="../assets/foto_sophron_website.jpg"
                        alt="Image Description"
                    />
                    <div>
                        <h5 class="text-sm text-secondary dark:text-gray-200">
                            By Sophron Vermeij
                        </h5>
                    </div>
                </div>
            </router-link>
            <!-- End Card -->

            <div class="lg:col-span-1"></div>
            <!-- Empty spacer for large screens -->
            <!-- Hidden spacer for medium screens -->
        </div>
        <!-- End Grid -->

        <!-- Card -->
        <!-- <div class="mt-12 text-center">
            <a
                class="py-3 px-4 inline-flex items-center gap-x-1 text-sm font-medium rounded-full border border-gray-200 bg-white text-accent shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-blue-500 dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                href="#"
            >
                Read more
                <svg
                    class="flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path d="m9 18 6-6-6-6" />
                </svg>
            </a>
        </div> -->
        <!-- End Card -->
    </div>
    <!-- End Card Blog -->
</template>

<script>
export default {
    name: "BlogSection",
    // You can add JavaScript logic here if needed
};
</script>

<style scoped>
.image-crop {
    width: 2rem; /* Adjust the width as needed */
    height: 2rem; /* Adjust the height as needed */
    object-fit: cover;
    object-position: center 10%;
    border-radius: 50%; /* To make it rounded */
}

@media (max-width: 768px) {
    .grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>
